import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { groupBy, uniq } from 'lodash';
import { Modal } from 'react-materialize';

import { determineIfSingleTotalColumn, priceOr } from '../../helper';
import TipInput from '../components/TipInput';

const ReviewServices = (props) => {
  const {
    z3pConfiguration,
    cart,
    diagnostic,
    tipAmountDollars,
    setTipAmountDollars,
    selectedPaymentMethod,
  } = props;
  const { is_accepting_tip, request_tip_at_checkout,is_hiding_price_in_booking } = z3pConfiguration;

  const showTipInput =
    selectedPaymentMethod?.is_square &&
    is_accepting_tip &&
    request_tip_at_checkout;

  const superscriptModal = (number, message, title) => {
    return (
      <Modal
        header={title}
        trigger={
          <sup style={{ color: '#9f7a7a', cursor: 'pointer' }}> [{number}]</sup>
        }
      >
        <p>{message}</p>
      </Modal>
    );
  };

  let bookingMessages = [];
  cart.forEach((s) => {
    bookingMessages.push(s.booking_message_1, s.booking_message_2);
  });
  bookingMessages = uniq(bookingMessages);

  const serviceOfferingIdToSuperscripts = {};
  cart.forEach((s) => {
    const superscripts = [];
    if (s.booking_message_1) {
      const i = bookingMessages.indexOf(s.booking_message_1);
      superscripts.push(
        superscriptModal(i + 1, s.booking_message_1, s.long_name),
      );
    }
    if (s.booking_message_2) {
      const i = bookingMessages.indexOf(s.booking_message_2);
      superscripts.push(
        superscriptModal(i + 1, s.booking_message_2, s.long_name),
      );
    }
    if (superscripts) {
      serviceOfferingIdToSuperscripts[s.service_offering_id] = superscripts;
    }
  });

  const groupedServices = groupBy(cart, 'short_name');
  const sortedServices = Object.keys(groupedServices);
  sortedServices.sort(
    (a, b) =>
      groupedServices[a].length * groupedServices[a][0].total_cost_dollars -
      groupedServices[b].length * groupedServices[b][0].total_cost_dollars,
  );
  const partsSubtotal = cart
    .map((s) => (parseFloat(s.parts_cost_dollars) || 0) * (s.quantity || 1))
    .reduce((sum, x) => sum + x, 0);
  const laborSubtotal = cart
    .map((s) => (parseFloat(s.labor_cost_dollars) || 0) * (s.quantity || 1))
    .reduce((sum, x) => sum + x, 0);
  const subtotal = cart
    .map((s) => (parseFloat(s.total_cost_dollars) || 0) * (s.quantity || 1))
    .reduce((sum, x) => sum + x, 0);

  const showTotalOnly = determineIfSingleTotalColumn(cart);

  const items = sortedServices.map((key) => {
    const s = groupedServices[key][0];
    const quantity = s.quantity || groupedServices[key].length;
    return (
      <Fragment key={s.short_name}>
        <tr key={s.short_name}>
          <td className="horizontal_dotted_line" style={{ textAlign: 'left' }}>
            {quantity > 1 && <span>{quantity}x </span>}

            {s.short_name}

            {serviceOfferingIdToSuperscripts[s.service_offering_id]}
          </td>

          {!showTotalOnly && !is_hiding_price_in_booking && (
            <>
              <td>{priceOr(quantity * s.parts_cost_dollars, '--')}</td>
              <td>{priceOr(quantity * s.labor_cost_dollars, '--')}</td>
            </>
          )}
          {!is_hiding_price_in_booking && (
          <td>{priceOr(quantity * s.total_cost_dollars, 'FREE')}</td>)}
        </tr>

        {s.short_name.includes('Diagnostic') && (
          <tr className="diagnostic-complete-description">
            {diagnostic.completeDescription}
          </tr>
        )}
      </Fragment>
    );
  });

  return (
    <>
      <table className="review-service-table">
        <tr>
          <th style={{ textAlign: 'left' }}>Service</th>
          {!showTotalOnly ? (
            !is_hiding_price_in_booking && (
              <>
                <th width="20%">Parts</th>
                <th width="20%">Labor</th>
                <th>Total</th>
              </>
            )
          ) : (
            <>
            {!is_hiding_price_in_booking && ( <th>Cost</th> )}
            </>
          )}
        </tr>
        {items}
        {!is_hiding_price_in_booking && (
          <tr>
          <td style={{ textAlign: 'left', fontWeight: 'bold' }}>Subtotal</td>

            {!showTotalOnly && (
              <>
                <td>{priceOr(partsSubtotal, '--')}</td>
                <td>{priceOr(laborSubtotal, '--')}</td>
              </>
            )}
            <td style={{ fontWeight: 'bold' }}>{priceOr(subtotal, 'FREE')}</td>
          </tr>
        )}
      </table>
      {showTipInput && (
        <TipInput
          subtotalDollars={subtotal}
          tipAmountDollars={tipAmountDollars}
          setTipAmountDollars={setTipAmountDollars}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    diagnostic: state.ui.diagnostic,
    cart: state.ui.cart,
    selectedPaymentMethod: state.ui.selectedPaymentMethod,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(ReviewServices);
