/* globals window */

import React, { Component } from 'react';
import { CardPanel, Col, Row, Icon, Button } from 'react-materialize';
import moment from 'moment';
import Raven from 'raven-js';
import { connect } from 'react-redux';
import Zippity from './components/Zippity';
import { apiGet, apiPost, apiPut, hasAccessToken } from '../brainApi';
import {
  getEmailSupportLink,
  getPhoneSupportLink,
  setPageTitle,
  determineIfSingleTotalColumn,
  priceOr,
  convertPhoneFormat,
  handleRescheduleClick,
} from '../helper';
import AddPaymentWithoutLoginModal from './AddPaymentWithoutLoginModal';
import GoogleMap from './Geolocation/GoogleMap';
import { Location } from './Geolocation/CheckinGeolocation';
import CancelServiceModal from './components/CancelServiceModal';
import cancelService from '../appointmentHelper';
import TipInput from './components/TipInput';
import { addMessage } from '../zippity';

class AddPaymentWithoutLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isErrored: false,
      errorMessage: '',
      scheduledWork: {},
      resendStatus: {
        color: 'red',
        status: 'For security reasons, your token has expired',
        isDisabled: false,
      },
      showTotalOnly: true,
      hasCard: false,
      isCancelServiceModalOpen: false,
      cancelling: false,
      newStatus: '',
      tipAmountDollars: 0,
      totalTaxedPriceDollars: 0,
      processing: false,
      isPaid: null,
      minimumServiceChargeAmountDollars: 0,
    };
  }

  async componentDidMount() {
    setPageTitle('Appointment');
    const { params, route } = this.props;
    const { token, sw_id } = params;
    const { path } = route;
    const isApptRoute = path.includes('appointment');
    let response = {};

    // Get the details on this SW
    if (hasAccessToken() && isApptRoute) {
      const res = await apiGet('/customer-info');
      const resJson = await res.json();
      const customerId = resJson.customer.customer_id;
      response = await apiGet(`/scheduled-work/${sw_id}/${customerId}`);
    } else {
      response = await apiGet(`/scheduled-work-token/${token}`);
    }
    try {
      const json = await response.json();
      if (response.status === 200) {
        if (isApptRoute && json.id !== Number(sw_id)) {
          this.setState({
            isErrored: true,
            errorMessage: 'Invalid URL',
            loading: false,
          });
        } else {
          this.setState({
            scheduledWork: json,
            loading: false,
            tipAmountDollars: json.tip_amount_dollars,
            totalTaxedPriceDollars:
              json.total_taxed_price_dollars - json.tip_amount_dollars,
            isPaid: json.amount_paid_dollars >= json.total_taxed_price_dollars,
            minimumServiceChargeAmountDollars:
              json.minimum_service_charge_amount_dollars,
          });
        }
      } else {
        this.setState({
          isErrored: true,
          errorMessage: json.Message,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        isErrored: true,
        loading: false,
      });
      console.log(error);
      Raven.captureException(error);
    }
  }

  submitTip = async () => {
    const { scheduledWork, tipAmountDollars } = this.state;
    const { dispatch, params } = this.props;
    const { token } = params;
    this.setState({ processing: true });
    try {
      const res = await apiPut(`/booking/scheduled-work/${scheduledWork.id}`, {
        token,
        tip_amount_dollars: tipAmountDollars,
        tip_entry_method: 'online-appt',
      });
      if (res.status !== 200) throw new Error();
      addMessage(dispatch, 'Tip updated', 10);
      window.location.reload();
    } catch {
      addMessage(dispatch, 'Failed to add tip', 10, { messageType: 'error' });
    } finally {
      this.setState({ processing: false });
    }
  };

  render() {
    const {
      loading,
      scheduledWork,
      isErrored,
      errorMessage,
      resendStatus,
      showTotalOnly,
      hasCard,
      isCancelServiceModalOpen,
      cancelling,
      newStatus,
      tipAmountDollars,
      totalTaxedPriceDollars,
      processing,
      isPaid,
      minimumServiceChargeAmountDollars,
    } = this.state;
    const {
      vehicle,
      subtotal_dollars,
      amount_paid_dollars,
      sales_tax_dollars,
      shop_fee_dollars,
      coupon_amount_dollars,
      coupon_code,
      custom_discount_amount_dollars,
      custom_discount_reason,
      customer = {},
      customer_address = {},
      status,
      date,
      confirmed_appointment_start_time,
      services = [],
      customer_location,
      hc_is_complete,
      health_check_url,
      payment_method,
      square_card_id,
      tip_amount_dollars,
    } = scheduledWork;
    const {
      stripe_last4,
      stripe_brand,
      square_last4,
      square_brand,
      first_name,
      last_name,
      email: customerEmail,
      phone: customerPhone,
    } = customer;
    const { lat, lng } = customer_address || {};
    const { is_stripe, is_square, name: paymentMethod } = payment_method || {};
    const isCCOnline = is_stripe || is_square;

    let cc_last4 = is_stripe && stripe_last4 !== '?' ? stripe_last4 : null;
    let cc_brand = is_stripe && stripe_brand !== '?' ? stripe_brand : null;
    if (square_card_id && (square_last4 || square_brand)) {
      cc_last4 = square_last4;
      cc_brand = square_brand;
    }

    const { z3pConfiguration, params, dispatch } = this.props;
    const {
      customer_facing_email,
      customer_facing_phone_raw,
      client_url,
      is_accepting_tip,
      enable_hold_for_tip,
      is_hiding_price_in_booking,
    } = z3pConfiguration;
    const { token } = params;

    const activeStatus = newStatus || status;

    const buttonText = () => {
      if (processing) return 'Processing...';
      if (activeStatus === 'finished' && hasCard) {
        return 'Pay';
      }
      if (tip_amount_dollars > 0) {
        return 'Update Tip';
      }
      return 'Add Tip';
    };

    const handleResendEmail = () => {
      this.setState({
        resendStatus: {
          color: 'grey',
          status: 'Processing...',
          isDisabled: true,
        },
      });
      return apiPost('/send-failed-payment-notification', {
        sw_token: token,
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              resendStatus: {
                color: 'green',
                status: 'A new payment link email/text is on its way!',
                isDisabled: true,
              },
            });
          }
        })
        .catch((error) => console.error(error));
    };

    if (isErrored && errorMessage === 'Token is expired.') {
      return (
        <Zippity>
          <Row>
            <Col s={12} className="center">
              <p>
                If you have any questions, please contact us at{' '}
                {getEmailSupportLink()} or {getPhoneSupportLink()}.
              </p>
              <div>
                <CardPanel className={`${resendStatus.color} lighten-4`}>
                  {resendStatus.status}
                </CardPanel>
              </div>
              <Button
                disabled={resendStatus.isDisabled}
                onClick={handleResendEmail}
              >
                RESEND ACCESS LINK
              </Button>
            </Col>
          </Row>
        </Zippity>
      );
    }

    if (isErrored) {
      return (
        <Zippity>
          <Row>
            <Col s={12} className="center">
              <h2>{errorMessage}</h2>
            </Col>
          </Row>
        </Zippity>
      );
    }

    if (loading) {
      return (
        <Zippity>
          <Row>
            <Col s={12} className="center">
              <h2>Loading...</h2>
            </Col>
          </Row>
        </Zippity>
      );
    }

    const vehicleDescription = vehicle?.display_name;

    const allConfirmedServices = services.filter(
      (s) => !s.is_upsell || s.upsell_status === 'approved',
    );
    const isFree = totalTaxedPriceDollars + tipAmountDollars === 0;

    // format appointment and vehicle
    const appointmentDate = moment(date).format('dddd, MMMM DD');
    let appointmentTime = '';
    if (confirmed_appointment_start_time) {
      appointmentTime = moment(
        confirmed_appointment_start_time,
        'HH:mm:ss',
      ).format('h:mma');
    }
    const canOnlyShowTotal = determineIfSingleTotalColumn(allConfirmedServices);

    const customerName = `${first_name} ${last_name}`;

    const mapLocation = lat && lng ? Location.Create(lat, lng) : null;

    const isApptUpcoming =
      ['scheduled', 'keys_available'].includes(activeStatus) &&
      !moment(date).isBefore(new Date(), 'day');

    const setHasCard = (hC) => this.setState({ hasCard: hC });

    const showTipInput =
      enable_hold_for_tip &&
      is_accepting_tip &&
      is_square &&
      !isPaid && // TODO remove condition once an appt can have multiple transactions
      activeStatus !== 'cancelled';

    const statusBar = () => {
      if (activeStatus === 'cancelled') {
        return <b>Cancelled</b>;
      }
      return status !== 'finished' ? (
        <div>Add Payment Method</div>
      ) : (
        <div>
          Appointment Complete
          {!isPaid && !hasCard && (
            <>
              : <b>PAYMENT DUE</b>
            </>
          )}
        </div>
      );
    };

    return (
      <Zippity>
        <h4>
          <b>
            Your Appointment on {appointmentDate}{' '}
            {confirmed_appointment_start_time && ` at ${appointmentTime}`}
          </b>
        </h4>

        {((!hasCard && (is_stripe || is_square)) ||
          ['finished', 'cancelled'].includes(activeStatus)) && (
          <Row
            key="status-banner"
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'whitesmoke',
              borderRadius: 10,
              padding: 5,
              marginLeft: 0,
            }}
          >
            {statusBar()}
          </Row>
        )}
        <Row key="body">
          <Col s={12} m={6} style={{ marginBottom: 10 }}>
            <p>
              <b>Services</b>
            </p>
            <p>{vehicleDescription}</p>
            {!is_hiding_price_in_booking && showTotalOnly && !canOnlyShowTotal && (
              <Row
                key="breakdown"
                role="button"
                tabIndex="0"
                onClick={() =>
                  !canOnlyShowTotal && this.setState({ showTotalOnly: false })
                }
                onKeyPress={this.handleKeyPress}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  color: '#008fcf',
                  fontSize: 12,
                  borderBottom: '1px solid #ccc',
                  cursor: 'pointer',
                }}
              >
                View Parts/Labor Breakdown
              </Row>
            )}
            {!showTotalOnly && (
              <Row
                key="table-header"
                style={{
                  borderBottom: '1px solid #ccc',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Col m={6} s={6}>
                  <b>Services</b>
                </Col>
                {!is_hiding_price_in_booking && (
                  <>
                    <Col
                      m={2}
                      s={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <b>Parts</b>
                    </Col>
                    <Col
                      m={2}
                      s={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <b>Labor</b>
                    </Col>

                    <Col
                      m={2}
                      s={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <b>Total</b>
                    </Col>
                  </>
                )}
              </Row>
            )}
            {allConfirmedServices.map((s) => (
              <Row
                key={s.id}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Col
                  m={is_hiding_price_in_booking ? 12 : 6}
                  s={is_hiding_price_in_booking ? 12 : 6}
                >
                  {s.display_name || s.long_name}
                </Col>
                {!is_hiding_price_in_booking &&
                  (!showTotalOnly ? (
                    <>
                      <Col
                        m={2}
                        s={2}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {priceOr(s.parts_cost_dollars, '--')}
                      </Col>
                      <Col
                        m={2}
                        s={2}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {priceOr(s.labor_cost_dollars, '--')}
                      </Col>
                      <Col
                        m={2}
                        s={2}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {priceOr(s.total_cost_dollars, 'FREE')}
                      </Col>
                    </>
                  ) : (
                    <Col
                      m={6}
                      s={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {priceOr(s.total_cost_dollars, 'FREE')}
                    </Col>
                  ))}
              </Row>
            ))}
            {!is_hiding_price_in_booking && (
              <>
                <Row
                  key="subtotal"
                  style={{
                    borderTop: '1px solid #ccc',
                    paddingTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <Col m={9} s={9} className="cost-field">
                    Subtotal
                  </Col>
                  <Col m={3} s={3} className="cost-field">
                    {`$${subtotal_dollars.toFixed(2)}`}
                  </Col>
                </Row>

                {coupon_amount_dollars > 0 && (
                  <Row key="coupon" style={{ marginBottom: 5 }}>
                    <Col m={9} s={9} className="cost-field">
                      Coupon {coupon_code || ''}
                    </Col>
                    <Col
                      m={3}
                      s={3}
                      className="cost-field"
                    >{`- $${coupon_amount_dollars.toFixed(2)}`}</Col>
                  </Row>
                )}
                {custom_discount_amount_dollars > 0 && (
                  <Row key="discount" style={{ marginBottom: 5 }}>
                    <Col m={9} s={9} className="cost-field">
                      {custom_discount_reason || 'Custom Discount'}
                    </Col>
                    <Col
                      m={3}
                      s={3}
                      className="cost-field"
                    >{`- $${custom_discount_amount_dollars.toFixed(2)}`}</Col>
                  </Row>
                )}
                {shop_fee_dollars > 0 && (
                  <Row key="fee" style={{ marginBottom: 5 }}>
                    <Col m={9} s={9} className="cost-field">
                      Supplies and Environmental Disposal Fee
                    </Col>
                    <Col
                      m={3}
                      s={3}
                      className="cost-field"
                    >{`$${shop_fee_dollars.toFixed(2)}`}</Col>
                  </Row>
                )}
                <Row key="taxes" style={{ marginBottom: 5 }}>
                  <Col m={9} s={9} className="cost-field">
                    Taxes
                  </Col>
                  <Col
                    m={3}
                    s={3}
                    className="cost-field"
                  >{`$${sales_tax_dollars.toFixed(2)}`}</Col>
                </Row>
                {!!minimumServiceChargeAmountDollars && (
                  <Row key="minimumServiceCharge" style={{ marginBottom: 5 }}>
                    <Col m={9} s={9} className="cost-field">
                      Minimum Service Charge
                    </Col>
                    <Col m={3} s={3} className="cost-field">
                      {`$${minimumServiceChargeAmountDollars.toFixed(2)}`}
                    </Col>
                  </Row>
                )}
                {!!tipAmountDollars && (
                  <Row key="tip" style={{ marginBottom: 5 }}>
                    <Col m={9} s={9} className="cost-field">
                      Tip
                    </Col>
                    <Col
                      m={3}
                      s={3}
                      className="cost-field"
                    >{`$${tipAmountDollars.toFixed(2)}`}</Col>
                  </Row>
                )}
                <Row key="total" style={{ marginBottom: 5 }}>
                  <Col m={9} s={9} className="cost-field">
                    <b>Total</b>
                  </Col>
                  <Col m={3} s={3} className="cost-field">
                    <b>{`$${(totalTaxedPriceDollars + tipAmountDollars).toFixed(
                      2,
                    )}`}</b>
                  </Col>
                </Row>
              </>
            )}
            {isFree ? (
              <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                No payment necessary.
              </p>
            ) : (
              <AddPaymentWithoutLoginModal
                token={token}
                email={customerEmail}
                ccLast4={cc_last4}
                ccBrand={cc_brand}
                setHasCard={setHasCard}
                isPaid={isPaid}
                isFinished={activeStatus === 'finished'}
                isCCOnline={isCCOnline}
                paymentMethod={paymentMethod}
                tipAmountDollars={tipAmountDollars}
                setIsPaid={(bool) => this.setState({ isPaid: bool })}
              />
            )}
            {showTipInput && (
              <>
                <TipInput
                  tipAmountDollars={tipAmountDollars}
                  subtotalDollars={subtotal_dollars}
                  setTipAmountDollars={(num) =>
                    this.setState({ tipAmountDollars: num || 0 })
                  }
                />
                <div style={{ marginTop: '30px', textAlign: 'center' }}>
                  <Button onClick={this.submitTip} disabled={processing}>
                    {buttonText()}
                  </Button>
                </div>
              </>
            )}
            {isApptUpcoming && (
              <Row
                key="reschedule-cancel"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <Col>
                  <u
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      handleRescheduleClick(
                        scheduledWork,
                        dispatch,
                        vehicle,
                        token,
                      )
                    }
                    onKeyPress={this.handleKeyPress}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    RESCHEDULE
                  </u>
                </Col>
                <Col>or</Col>
                <Col>
                  <u
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      this.setState({ isCancelServiceModalOpen: true })
                    }
                    onKeyPress={this.handleKeyPress}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    CANCEL
                  </u>
                </Col>
              </Row>
            )}
            <CancelServiceModal
              open={isCancelServiceModalOpen}
              mainText="Are you sure you want to cancel?"
              cancelling={cancelling}
              confirmText={cancelling ? 'Cancelling...' : 'Cancel Service'}
              closeModalText="Go Back"
              onConfirm={(
                reason,
                details,
                feeToCharge,
                setAllowSelfServiceCancellation,
                setErrorMessage,
              ) => {
                if (!cancelling)
                  cancelService(
                    reason,
                    details,
                    feeToCharge,
                    setAllowSelfServiceCancellation,
                    setErrorMessage,
                    scheduledWork,
                    dispatch,
                    (value) => this.setState({ cancelling: value }),
                    (value) =>
                      this.setState({ isCancelServiceModalOpen: value }),
                    () => {},
                    (value) => this.setState({ newStatus: value }),
                    token,
                  );
              }}
              onCloseModal={() => {
                if (!cancelling)
                  this.setState({ isCancelServiceModalOpen: false });
              }}
              onReschedule={() =>
                handleRescheduleClick(scheduledWork, dispatch, vehicle, token)
              }
              sw={scheduledWork}
            />
          </Col>
          <Col
            s={12}
            m={6}
            style={window.screen.width > 600 ? { paddingLeft: 75 } : undefined}
          >
            {hc_is_complete && (
              <a
                style={{
                  display: 'flex',
                  borderRadius: 10,
                  borderStyle: 'solid',
                  marginBottom: 5,
                  color: '#008fcf',
                  justifyContent: 'space-between',
                  textDecoration: 'none',
                }}
                href={health_check_url}
                target="_blank"
                rel="noreferrer"
              >
                <Icon style={{ padding: 5 }}>check_circle</Icon>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  View Service Report
                </div>
                <Icon style={{ padding: 5 }}>open_in_new</Icon>
              </a>
            )}
            {mapLocation && (
              <div style={{ position: 'relative', height: '400px' }}>
                <GoogleMap
                  defaultLocation={mapLocation}
                  defaultIcon
                  style={{ borderRadius: 10 }}
                  mapType="roadmap"
                />
              </div>
            )}
            <div>
              <b>Location: </b>
              {customer_location}
            </div>
            <div
              style={{
                backgroundColor: 'whitesmoke',
                marginTop: 10,
                borderRadius: 10,
                padding: 10,
              }}
            >
              <div>
                <b>Customer Information</b>
              </div>
              <div style={{ display: 'flex' }}>
                <Icon left>person</Icon> {customerName}
              </div>
              {customerEmail && (
                <div style={{ display: 'flex' }}>
                  <Icon left>mail_outline</Icon> {customerEmail}
                </div>
              )}
              <div style={{ display: 'flex' }}>
                <Icon left>phone_iphone</Icon>{' '}
                {convertPhoneFormat(customerPhone)}
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                padding: 10,
              }}
            >
              <div>
                <b>Contact Us</b>
              </div>
              <div style={{ display: 'flex' }}>
                <Icon left>language</Icon>{' '}
                <a href={client_url} target="_blank" rel="noreferrer">
                  {client_url.replace('https://', '')}
                </a>
              </div>
              <div style={{ display: 'flex' }}>
                <Icon left>mail_outline</Icon> {customer_facing_email}
              </div>
              <div style={{ display: 'flex' }}>
                <Icon left>phone_iphone</Icon>{' '}
                {convertPhoneFormat(customer_facing_phone_raw)}
              </div>
            </div>
          </Col>
        </Row>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(AddPaymentWithoutLogin);
